<template>
  <Stations class="bg-dark" />
  <!-- <Station class="bg-dark" /> -->
</template>

<script>
import Stations from "./components/Stations.vue";
import Station from "./components/Station.vue";

export default {
  name: "App",
  components: {
    Stations,
    Station,
  },
};
</script>

<style>
</style>


