<template>
  <div class="container-fluid chart-container">
    <div class="row border-top border-white">
      <div class="col">
        <div
          v-if="station_data[0]"
          class="card bg-dark text-white">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <p>
                  {{ station_data && station_data[0].name }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p class="text-muted">Winds</p>
              </div>

              <div class="col text-end">
                <p class="text-muted">last 24 hours</p>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div v-if="station_data_array.length">
              <line-chart
                width="100%"
                height="15vh"
                :data="station_data_array"
                :colors="['#999']"
                suffix=" knots"
                :points="false"
                class="bg-dark text-white"></line-chart>
            </div>
          </div>

          <div class="card-header">
            <div class="row">
              <div class="col">
                <p class="text-muted">Tides</p>
              </div>
            </div>

            <div class="card-body">
              <area-chart
                width="100%"
                height="10vh"
                :data="tides_data"
                :colors="['#666', '#333']"
                :legend="false"
                :ymax="4"
                suffix="m"
                class="bg-dark text-white"></area-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- {{ station_data_array }} -->

  <!-- <Bar
    :chart-data="chartData"
    :chart-options="chartOptions" /> -->
</template>

<script>
import {
  query,
  where,
  orderBy,
  limit,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { db } from "@/firebaseConfig";
// import Tides from "../assets/Tides.json";

export default {
  name: "Station",
  props: {
    station: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      station_data: [],
      tides: [],
      meta_tides: [],
      library: {
        scales: {
          x: {
            type: "time",
            time: {
              unit: "minute",
            },
          },
        },
      },

      // chartOptions: {
      //   annotation: {
      //     annotations: [
      //       {
      //         type: "line",
      //         mode: "vertical",
      //         scaleID: "x-axis-0",
      //         value: "2022-07-21 12:00:00",
      //         borderColor: "red",
      //         label: {
      //           content: "TODAY",
      //           enabled: true,
      //           position: "top",
      //         },
      //       },
      //     ],
      //   },
      // },
    };
  },
  // created() {
  //   this.get_station_data();
  // },
  methods: {
    async get_station_data(code) {
      onSnapshot(
        // use 'query()' instead of a reference
        query(
          collection(db, "stations_data_series"),
          where("code", "==", code),
          orderBy("last_update", "desc"),
          limit(144)
        ),
        (snap) => {
          snap.forEach((doc) => {
            this.station_data.push(doc.data());
          });
        }
      );
    },

    async get_tides() {
      const url = `https://api.niwa.co.nz/tides/data?lat=${this.station.coordinates[1]}&long=${this.station.coordinates[0]}&interval=10&numberOfDays=1`;

      let response = await fetch(url, {
        method: "GET",
        headers: {
          "x-apikey": "WAGAQuMpkAmFGPBhV8HVWIyBIVKaQsSc",
          "Content-Type": "application/json;charset=utf-8",
        },
      });

      let result = await response.json();
      this.tides = result;
    },

    async get_meta_tides() {
      const url = `https://api.niwa.co.nz/tides/data?lat=${this.station.coordinates[1]}&long=${this.station.coordinates[0]}&numberOfDays=1`;

      let response = await fetch(url, {
        method: "GET",
        headers: {
          "x-apikey": "WAGAQuMpkAmFGPBhV8HVWIyBIVKaQsSc",
          "Content-Type": "application/json;charset=utf-8",
        },
      });

      let result = await response.json();
      this.meta_tides = result;
    },

    transform_iso_date(date) {
      const local_date = new Date(date);
      const YYYY = local_date.getFullYear();
      const MM = local_date.getMonth() + 1;
      const DD = String(local_date.getDate()).padStart(2, "0");
      const hh = String(local_date.getHours()).padStart(2, "0");
      const mm = String(local_date.getMinutes()).padStart(2, "0");
      return `${YYYY}-${MM}-${DD} ${hh}:${mm}:00`;
      // return `${hh}:${mm}`;
    },
  },

  computed: {
    station_data_array() {
      return this.station_data.map((s) => {
        const new_date = this.transform_iso_date(s.last_update);
        return [new_date, s.average_wind_speed];
      });
    },

    tides_data() {
      const tides =
        this.tides.values &&
        this.tides.values.length &&
        this.tides.values.map((s) => {
          const new_date = this.transform_iso_date(s.time);
          return [new_date, s.value];
        });

      let prior_dates =
        tides.length && tides.filter((t) => new Date(t[0]) <= new Date());
      let post_dates = tides.length && tides.slice(prior_dates.length - 1);

      let chart_data = [
        // { points: true, data: [[...tides[10]]] },
        // { points: false, data: [...tides] },

        { points: false, data: prior_dates },
        { points: false, data: post_dates },
      ];

      return chart_data;
    },

    next_high_tide() {},
  },

  watch: {
    async station(new_station) {
      this.station_data = [];
      if (new_station && new_station.code) {
        await this.get_station_data(new_station.code);
        this.get_tides();
        // this.get_meta_tides();
      }
    },
  },
};
</script>

<style>
.chart-container {
  min-height: 25vh;
}
</style>