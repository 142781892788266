import { initializeApp } from "firebase/app";
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDDOYF0jB-QN3fjF3NA-owYVdXdcXN9woc",
    authDomain: "weatherstationsnz.firebaseapp.com",
    projectId: "weatherstationsnz",
    storageBucket: "weatherstationsnz.appspot.com",
    messagingSenderId: "745191338913",
    appId: "1:745191338913:web:519ce6ade6a5ed99c891e0",
    measurementId: "G-L7EXSLFD32"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { functions, db, analytics }

// import { getFunctions, httpsCallable } from "firebase/functions";

// const functions = getFunctions();
// const addMessage = httpsCallable(functions, 'addMessage');
// addMessage({ text: messageText })
//   .then((result) => {
//     // Read result of the Cloud Function.
//     /** @type {any} */
//     const data = result.data;
//     const sanitizedMessage = data.text;
//   })
//   .catch((error) => {
//     // Getting the Error details.
//     const code = error.code;
//     const message = error.message;
//     const details = error.details;
//     // ...
//   });
