<template>
  <div>
    <div class="container-fluid">
      <div class="row text-center">
        <h6 class="display-6 text-light">NOWCASTING</h6>
        <p class="text-small text-muted">LAST UPDATED: {{ last_updated }}</p>
      </div>

      <div class="row m-2 p-2">
        <div class="col-xl-4 p-2">
          <!-- <div class="card sticky-top">
            <div class="card-body"> -->
          <table class="table table-dark table-responsive table-sm table-hover">
            <thead class="sticky-top">
              <tr>
                <th
                  scope="col"
                  class="text-start">
                  Name
                </th>
                <th class="text-center">Average (kt)</th>
                <th class="text-center">Peak (kt)</th>
                <th class="text-center">Direction</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="station in ordered_stations"
                :key="station.Id"
                @click="center_station = station">
                <td class="text-start">
                  {{ station.name }}
                </td>

                <td class="text-center">{{ station.average_wind_speed }}</td>
                <td class="text-center">{{ station.peak_wind_speed }}</td>
                <td
                  v-if="
                    Number(station.average_wind_speed) +
                      Number(station.peak_wind_speed) +
                      Number(station.wind_direction) >
                    0
                  "
                  class="text-center">
                  {{ station.wind_direction.toString().padStart(3, "0") }}&deg;
                </td>
                <td
                  v-else
                  class="text-center">
                  -
                </td>
                <td>
                  <vue-feather
                    type="arrow-down"
                    :class="color_class(station.average_wind_speed)"
                    :style="
                      rotation_style(station.wind_direction)
                    "></vue-feather>
                </td>
              </tr>
            </tbody>
          </table>
          <Station
            :station="center_station"
            class="d-none d-lg-block footer--sticky bg-dark" />
          <!-- </div>
          </div> -->
        </div>
        <div class="col-xl-8 sticky-top p-2">
          <div class="sticky-top">
            <!-- <div class="card sticky-top">
            <div class="card-body"> -->

            <div
              id="map"
              class="sticky-top" />
          </div>
        </div>
        <!-- </div>
        </div> -->
        <hr class="text-light">
        <div class="col text-light">
          <h6>API</h6>
        <p class="text-small text-muted"><a href="https://nowcasting.nz/api/latest" target="_blank" class="text-small text-muted">https://nowcasting.nz/api/latest</a> [wind - every 10 minutes]</p>
        <p class="text-small text-muted"> <a href="https://nowcasting.nz/api/observations" target="_blank" class="text-small text-muted">https://nowcasting.nz/api/observations</a> [observations - every 3 hours]</p>
      </div>
      </div>
     
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { orderBy } from "lodash";
import { db } from "@/firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";
import Station from "./Station";
//  import Stations from "../assets/Stations.json";

export default {
  name: "Stations",
  components: { Station },
  async mounted() {
    await this.get_stations_data();
    this.get_user_location();
  },

  // created() {

  // },

  data() {
    return {
      mapRef: null,
      stations_data: [],
      closest_station: [],
      last_updated: "",
      center_station: [],
      location: null,
      getting_location: false,
      errorStr: null,
    };
  },

  methods: {
    get_user_location() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }
      this.getting_location = true;
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.getting_location = false;
          this.location = pos;
        },
        (err) => {
          this.getting_location = false;
          this.errorStr = err.message;
        }
      );
    },

    get_closest_station(pos) {
      const origin = [pos.coords.longitude, pos.coords.latitude];

      const closest = this.stations_data.reduce((a, b) =>
        this.distance(a.coordinates, origin) <
        this.distance(b.coordinates, origin)
          ? a
          : b
      );

      this.center_station = closest;
    },

    distance(point, origin) {
      return Math.sqrt(
        Math.pow(origin[0] - point[0], 2) + Math.pow(origin[1] - point[1], 2)
      );
    },

    async get_stations_data() {
      // use 'collection()' instead of 'doc()'
      onSnapshot(
        doc(db, "stations_data_latest", "stations_data_latest"),
        (snap) => {
          this.stations_data = snap.data().stations_data_array;
          this.last_updated = snap
            .data()
            .entered.toDate()
            .toLocaleTimeString("en-NZ");
        }
      );
    },
    async createMap() {
      try {
        mapboxgl.accessToken =
          "pk.eyJ1IjoiYXh3IiwiYSI6ImNsNDN2bjc1dTBqcHozYm4ycGk5aXZvOGYifQ.-5UF1q4fPIxRuwN1bWKYig";

        const stations_data = this.stations_data;
        const generateFeature = this.generateFeature;

        const mapRef = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/dark-v10",
          sprite: "mapbox://styles/mapbox/dark-v10/sprite@2x",
          center: [174.7787, -41.2924],
          zoom: 12,
        });

        this.mapRef = mapRef;

        mapRef.on("load", function () {
          // Add markers to map
          mapRef.addLayer({
            id: "places",
            type: "symbol",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: stations_data.map(generateFeature),
              },
            },
            layout: {
              "icon-image": "border-dot-13",
              "icon-size": 2,
              "icon-allow-overlap": true,
            },
          });

          mapRef.on("click", "places", function ({ features }) {
            const match = features[0];
            const coordinates = match.geometry.coordinates.slice();

            mapRef.flyTo({
              center: coordinates,
            });

            // Show popup
            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(match.properties.description)
              .addTo(mapRef);
          });

          // Change the cursor to a pointer when the mouse is over the places layer.
          mapRef.on("mouseenter", "places", function () {
            mapRef.getCanvas().style.cursor = "pointer";
          });

          // Change it back to a pointer when it leaves.
          mapRef.on("mouseleave", "places", function () {
            mapRef.getCanvas().style.cursor = "";
          });
        });
      } catch (error) {
        console.log(error);
      }
    },

    generateFeature(
      {
        name,
        average_wind_speed,
        peak_wind_speed,
        wind_direction,
        last_update,
        coordinates,
      },
      index
    ) {
      return {
        type: "Feature",
        properties: {
          description: `<div class="text-center"><p><b>${name}</b><br/>Average: ${average_wind_speed}<br/>Peak: ${peak_wind_speed}<br/>Direction: ${wind_direction
            .toString()
            .padStart(3, "0")}&deg;<br/>Last Updated: ${this.transform_iso_date(
            last_update
          )}</p><div style="transform: rotate(${wind_direction}deg);"><h3><b>&#8681;</b></h3></div></div>`,
        },
        geometry: {
          type: "Point",
          coordinates,
        },
      };
    },
    rotation_style(deg) {
      return `transform: rotate(${deg}deg);`;
    },
    color_class(speed) {
      if (speed == 0) {
        return "text-secondary";
      } else if (speed > 0 && speed < 10) {
        return "text-success";
      } else if (speed >= 10 && speed < 20) {
        return "text-warning";
      } else if (speed >= 20) {
        return "text-danger";
      }
    },
    transform_iso_date(date) {
      const local_date = new Date(date);

      return local_date.toLocaleTimeString();
    },
  },

  computed: {
    ordered_stations() {
      return orderBy(this.stations_data, "coordinates[1]", "desc");
    },

    stations_and_location() {
      return (
        this.stations_data &&
        this.stations_data.length &&
        this.location &&
        this.location.coords
      );
    },

    // map_center() {
    //   const my_location = this.location &&
    //     this.location.length && [
    //       this.location.coords.longitude,
    //       this.location.coords.latitude,
    //     ];

    //   return my_location || [174.886, 40.9006];
    // },
  },

  watch: {
    stations_data(data) {
      if (data.length) {
        this.createMap();
      }
    },

    stations_and_location(new_location) {
      if (new_location) {
        this.get_closest_station(this.location);
      }
    },

    center_station(new_station) {
      if (new_station) {
        let match = this.generateFeature(new_station);

        this.mapRef.flyTo({
          center: new_station.coordinates,
        });
        // const popup = new mapboxgl.Popup({ closeOnClick: true, closeOnMove: false })
        //   .setLngLat(new_station.coordinates)
        //   .setHTML(match.properties.description);
        // if (popup.isOpen()) {
        //   popup.remove();
        // } else {
        //   popup.addTo(this.mapRef);
        // }
      }
    },
  },
};
</script>

<style>
html {
  font-size: 0.9rem;
}

#map {
  height: 100vh;
}

.mapboxgl-popup {
  width: 400px;
  font-size: 1.1rem;
}

.windex {
  transform: rotate(20deg);
}
.observer,
.observer-row {
  max-height: 5px;
  padding: 0 !important;
}

.footer--sticky {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  z-index: 1055;
}
</style>