import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'tailwind-color-palette/css/tailwind-color-palette.css'

import VueFeather from 'vue-feather'
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'


const app = createApp(App)

app.component(VueFeather.name, VueFeather);
app.use(VueChartkick)

app.mount('#app')
